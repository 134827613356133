.custom-img {
	width: 160px;
	height: 160px;
}

.header-icon {
	width: 21px;
	height: 20px;
	margin-top: 3px !important;
}

.testimonial-custom-img {
	padding-left: 14px;
}

.custom-service-heading {
	min-height: 60px;
}

.skill-custom-tab {
	line-height: 80% !important;
	margin-top: 5px;
	margin-bottom: 15px;
	text-align: center;
}

.social-icons-upwork {
	font-size: 10px;
	width: 24px;
	margin-top: 4px;
}

.skill-element {
	border-radius: 25px;
	padding: 3px;
	padding-left: 8px;
	padding-right: 6px;
	font-weight: 300;
	font-size: 12px !important;
	white-space: nowrap;
	color: #161616;
}

.project-detail-info {
	white-space: pre-line;
	line-height: 1.8;
	font-size: 13px;
}

/* Container holding the image and text */
.responsive-container {
	position: relative;
	width: 100%;
	max-width: 100%;
}

/* Make the image responsive */
.responsive-image {
	width: 100%;
	height: auto;
}

/* Style the text */
.responsive-text {
	position: absolute;
	top: 75%; /* Center vertically */
	left: 50%; /* Center horizontally */
	transform: translate(-50%, -50%); /* Adjust for centering */
	color: white; /* Text color */
	font-size: 1.6rem; /* Responsive text size */
	text-align: center;
	font-family: Arial, sans-serif; /* You can change the font */
	padding: 10px; /* Optional: Padding around the text */
	border-radius: 10px; /* Optional: Rounded corners */

	background-image: linear-gradient(90deg, rgb(143 104 174 / 97%), #e6ae83);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: bold; /* Optional: Make the text bold */
}

// .custom-slider-image {
// 	object-fit: cover;
// 	background-repeat: no-repeat;
// 	background-position: center;
// }

// @media only screen and (min-width: 1201px) {
// 	.custom-slider-image {
// 		height: 350px !important;
// 		object-fit: cover;
// 		background-repeat: no-repeat;
// 		background-position: center;
// 	}
// }

// @media only screen and (min-width: 993px) and (max-width: 1200px) {
// 	.custom-slider-image {
// 		height: 300px !important;
// 		object-fit: cover;
// 		background-repeat: no-repeat;
// 		background-position: center;
// 	}
// }

// @media only screen and (min-width: 768px) and (max-width: 992px) {
// 	.custom-slider-image {
// 		height: 220px !important;
// 		object-fit: cover;
// 		background-repeat: no-repeat;
// 		background-position: center;
// 	}
// }

// @media only screen and (min-width: 200px) and (max-width: 767px) {
// 	.custom-slider-image {
// 		height: 200px !important;
// 		object-fit: cover;
// 		background-repeat: no-repeat;
// 		background-position: center;
// 	}
// }

.custom-portfolio-section {
	position: relative;
	padding-top: 1.5rem;
	padding-bottom: 7.5rem;
	overflow: hidden;
}

.gradient-custom-color-header {
	background: rgb(179, 221, 235);
	background: linear-gradient(
		127deg,
		rgba(179, 221, 235, 1) 0%,
		rgba(120, 186, 207, 1) 13%,
		rgba(89, 164, 179, 1) 60%,
		rgba(37, 132, 172, 1) 100%
	);
}

.custom-skill-text {
	color: black;
	background-image: linear-gradient(-225deg, #dceaf1 0%, #50a7c2 100%);
}

// .gradient-custom-color-header {
// 	background: linear-gradient(
// 		142deg,
// 		rgba(181, 209, 232, 1) 0%,          //sample 2
// 		rgba(84, 169, 237, 1) 13%,
// 		rgba(57, 136, 200, 1) 51%,
// 		rgba(115, 48, 199, 1) 100%
// 	);
// }

// .gradient-custom-color-header {
// 	background: linear-gradient(          //sample1
// 		90deg,
// 		rgba(27, 145, 170, 1) 0%,
// 		rgba(127, 200, 214, 1) 49%,
// 		rgba(27, 145, 170, 1) 100%
// 	) !important;
// }

.gradient-custom-color-footer {
	color: whitesmoke;
	background: linear-gradient(
		142deg,
		rgba(181, 209, 232, 1) 0%,
		//sample 2
		rgba(84, 169, 237, 1) 13%,
		rgba(57, 136, 200, 1) 51%,
		rgba(115, 48, 199, 1) 100%
	);
}

// .gradient-custom-color-footer {
// 	background: linear-gradient(
// 		90deg,
// 		rgba(70, 190, 207, 1) 0%,
// 		rgba(76, 205, 223, 1) 49%,
// 		rgba(70, 190, 207, 1) 100%
// 	) !important;
// }

/* Basic font styling for the header text */
.header-text {
	font-family: "Poppins", sans-serif; /* Modern and clean font */
	color: whitesmoke !important; /* Neutral dark color */
	text-transform: uppercase !important; /* Optional for all caps look */
	letter-spacing: 2px; /* Spaced out letters for a modern touch */
	text-align: center; /* Centered header text */
}

/* Add subtle shadow for depth */
.header-text {
	text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
	.header-text {
		font-size: 2rem !important; /* Adjust font size for mobile */
	}
}
